// Generated by Melange

import * as CssJs from "styled-ppx.emotion/CssJs.js";
import * as Curry from "melange.js/curry.js";
import * as MomentRe from "melange-moment/MomentRe.js";
import * as Shared__Common from "../shared/Common.js";
import * as React from "react";
import * as Client from "react-dom/client";
import * as JsxRuntime from "react/jsx-runtime";

const mainText = Curry._1(CssJs.style, [
      CssJs.label("mainText"),
      CssJs.fontWeight({
            NAME: "num",
            VAL: 700
          })
    ]);

const timeText = Curry._1(CssJs.style, [
      CssJs.label("timeText"),
      CssJs.fontSize({
            NAME: "pxFloat",
            VAL: 14
          })
    ]);

const Css = {
  mainText: mainText,
  timeText: timeText
};

function renderToElementWithId(component, id) {
  const node = document.querySelector("#" + id);
  if (node == null) {
    console.error("RR.renderToElementWithId : no element of id " + (id + " found in the HTML."));
    return ;
  }
  const root = Client.createRoot(node);
  root.render(component);
}

function hydrateToElementWithId(component, id) {
  const node = document.querySelector("#" + id);
  if (node == null) {
    console.error("RR.renderToElementWithId : no element of id '" + (id + "' found in the HTML."));
  } else {
    Client.hydrateRoot(node, component);
  }
}

function Client$Test(Props) {
  return React.createElement(JsxRuntime.Fragment, undefined, JsxRuntime.jsx("div", {
                  children: "TESTING",
                  className: mainText
                }), JsxRuntime.jsx("div", {
                  children: MomentRe.Moment.format("MMMM Do YYYY, h:mm:ss a", MomentRe.momentNow(undefined)),
                  className: timeText
                }));
}

const Test = {
  make: Client$Test
};

console.log("here");

const body = document.querySelector("body");

if (!(body == null)) {
  const text = Shared__Common.greet("Client");
  const p = document.createElement("p");
  p.innerText = text;
  body.appendChild(p);
  renderToElementWithId(JsxRuntime.jsx(Client$Test, {}), "root");
}

export {
  Css ,
  renderToElementWithId ,
  hydrateToElementWithId ,
  Test ,
}
/* mainText Not a pure module */
