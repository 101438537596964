// Generated by Melange


function greet(param) {
  if (param === "Client") {
    return "Hello from client";
  } else {
    return "Hello from server";
  }
}

export {
  greet ,
}
/* No side effect */
